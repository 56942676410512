<template>
  <TepmplateBlock mod-class content-class="mb-5" title-page="Профиль">
    <vuescroll>
      <div class="scroll-container">
        <div class="create-user">
          <div v-show="!isShowChangePasswordForm">
            <div
              v-if="isIntroPassed === 'false'"
              class="intro-tip mx-5 mt-5 w-50 text-center"
            >
              Заполните информацию о себе и нажмите кнопку Сохранить
            </div>
            <h2 class="admin-page__main-title">Профиль менеджера</h2>
            <form @submit.prevent="onSubmit">
              <div class="create-user__img-load-wrap">
                <label class="file-select">
                  <!-- <div class="select-button"></div> -->
                  <div
                    v-if="src === null"
                    ref="preview"
                    class="select-button"
                  ></div>
                  <img
                    v-else
                    class="preview-image admin-page__avatar"
                    :src="
                      this.src
                        ? this.src
                        : process.env.VUE_APP_URL +
                          managerProfile.profile_picture
                    "
                    alt=""
                  />
                  <!--          <span v-if="value">{{ value.name }}</span>-->
                  <!--          <span v-else>Загрузите фотографию устройства</span>-->
                  <input type="file" @change="handleFileChange" />
                </label>
              </div>

              <a
                class="d-flex justify-content-center mt-3 mb-3 tip-anchor"
                href="javascript:void(0)"
                @click="isShowChangePasswordForm = true"
                >Изменить пароль</a
              >

              <!--:class="{ 'over-top': isIntroPassed === 'false', 'valid': managerProfile.first_name.length > 3, 'invalid': managerProfile.first_name.length <= 3}"-->
              <Input
                v-model="managerProfile.first_name"
                label="Имя"
                :class="{ 'over-top': isIntroPassed === 'false' }"
                :newClass="isIntroPassed === 'false' ? 'over-top' : ''"
              />

              <Input
                v-model="managerProfile.last_name"
                label="Фамилия"
                :class="{ 'over-top': isIntroPassed === 'false' }"
                :newClass="isIntroPassed === 'false' ? 'over-top' : ''"
              />
              <!--
              <Input
                v-model="managerProfile.last_name"
                label="Фамилия"
                :class="getClassVisualParemeters(managerProfile.last_name, type)"
              />
                
              -->

              <Input
                :disabled="isIntroPassed === 'false'"
                v-model="managerProfile.email"
                label="E-mail"
                :noTabindex="isIntroPassed === 'false'"
              />

              <!-- <Input v-model="managerProfile.patronymic" label="Отчество" /> -->
              <!-- <div class="phone-block"> -->

              <div class="form-group">
                <label class="form-group__label phone-label">Телефон</label>
                <input
                  type="text"
                  class="form-control phone"
                  :class="{
                    'over-top': isIntroPassed === 'false'
                  }"
                  v-model="phoneNumber"
                  v-phone
                />
              </div>
              <button
                type="submit"
                class="btn btn-second mx-auto"
                :disabled="isFileUploadingInProgress"
                :class="{ 'over-top': isIntroPassed === 'false' }"
              >
                <span v-if="!isDataSubmitting">Сохранить</span>
                <b-spinner v-else small></b-spinner>
              </button>
            </form>
          </div>

          <ChangeUserPassword
            v-if="isShowChangePasswordForm"
            :loading="isChangePasswordDataSubmitting"
            @accept="changePassword($event)"
            @closeForm="isShowChangePasswordForm = false"
          />
        </div>
      </div>
    </vuescroll>
    <div v-if="isIntroPassed === 'false'" class="dark-intro-background">
      <div
        class="intro-tip-wrapper d-flex justify-content-end align-items-center"
      >
        <!-- <div class="intro-target"></div> -->
        <!-- <div v-if="isIntroPassed === 'false'" class="intro-tip mx-5 mt-5 w-25 text-center">
          Заполните информацию о себе и нажмите кнопку Сохранить
        </div> -->
      </div>
    </div>
  </TepmplateBlock>
</template>

<script>
import TepmplateBlock from "../../components/TepmplateBlock";
// import FormSelect from "../../components/Form/FormSelect";
import Input from "../../components/Form/FormElement/Input";
import vuescroll from "vuescroll";
import RequestManager from "../../function/request/RequestManager";
import ChangeUserPassword from "../../components/ChangeUserPassword";
import { doPoseTip } from "@/utils";
export default {
  name: "ManageProfile",
  components: {
    ChangeUserPassword,
    // FormSelect,
    Input,
    TepmplateBlock,
    vuescroll
  },
  /* computed: {
    ...mapGetters(["currentUser"])
  }, */
  watch: {},
  mounted() {
    this.currentUser = JSON.parse(window.localStorage.getItem("currentUser"));
    for (let key in this.currentUser) {
      if (this.managerProfile[key] !== undefined) {
        this.managerProfile[key] = this.currentUser[key];
      }
    }
    this.currentUser.profile_picture !== null &&
      (this.src = process.env.VUE_APP_URL + this.currentUser.profile_picture);
    if (
      this.currentUser?.phone_number !== null &&
      this.currentUser?.phone_number?.length > 0
    ) {
      // this.defaultPhoneCode = this.currentUser.phone_number.slice(0, 2);
      // this.phoneNumber = this.currentUser.phone_number.slice(2);
      this.phoneNumber = this.currentUser.phone_number;
    }
    if (this.isIntroPassed === "false") {
      doPoseTip(".tip-anchor", "top");
    }
  },
  data() {
    return {
      defaultPhoneCode: "",
      selected: "Админ",
      src: null,
      phoneCode: "",
      phoneNumber: "",
      isShowChangePasswordForm: false,
      managerProfile: {
        first_name: "",
        last_name: "",
        email: "",
        id: 0,
        phone: null,
        profile_picture: null,
        description: ""
      },
      options: [{ text: "+ 7", value: "+7" }],
      currentUser: null,
      isChangePasswordDataSubmitting: false,
      uploadedFile: undefined,
      isFileUploadingInProgress: false,
      isDataSubmitting: false
    };
  },
  methods: {
    getClassVisualParemeters(inputValue) {
      let resultString = "";
      resultString += inputValue.length > 3 ? "valid " : "invalid ";
      if (this.isIntroPassed === false) {
        resultString += "over-top";
      }
      return resultString;
    },

    changePassword(passwordData) {
      this.isChangePasswordDataSubmitting = true;
      RequestManager()
        .userApi.changePasswordUser(this.currentUser?.id, {
          oldPassword: passwordData.oldPassword,
          newPassword: passwordData.newPassword
        })
        .then(() => {
          this.isShowChangePasswordForm = false;
          this.$bvToast.toast("Пароль успешно изменен", {
            variant: "success",
            title: "Успешно"
          });
        })
        .catch(e => {
          const invalidOldPasswordMessage = "Неверный текущий пароль";
          this.$bvToast.toast(
            e.response.body.previous.message === "Invalid password."
              ? invalidOldPasswordMessage
              : "Возникла непредвиденная ошибка",
            {
              variant: "danger",
              title: "Ошибка"
            }
          );
          throw new Error(e);
        })
        .finally(() => (this.isChangePasswordDataSubmitting = false));
    },
    async handleFileChange(e) {
      const src = URL.createObjectURL(e.target.files[0]);
      this.managerProfile.profile_picture = e.target.files[0];
      this.src = src;
      // const data = new FormData();
      // data.append("profile_picture", e.target.files[0]);
      // RequestManager().updateUserPhoto(this.currentUser.id, data);
      this.isFileUploadingInProgress = true;
      const uploadedFileResponse = await RequestManager().filesApi.upload(
        "/",
        e.target.files[0]
      );
      this.isFileUploadingInProgress = false;
      this.uploadedFile = {
        title: uploadedFileResponse[0].name,
        objectType: "FileAttachment",
        path: uploadedFileResponse[0].path
      };
    },
    onCodeSelect(code) {
      this.phoneCode = code;
    },
    async onSubmit() {
      this.isDataSubmitting = true;
      const data = {
        ...this.managerProfile,
        profilePicture: this.uploadedFile
          ? this.uploadedFile.path
          : this.managerProfile.profile_picture,
        firstName: this.managerProfile.first_name,
        lastName: this.managerProfile.last_name,
        phone: this.phoneNumber
          .replaceAll("(", "")
          .replaceAll(")", "")
          .replaceAll("-", "")
          .replaceAll(" ", ""), // this.phoneCode + this.phoneNumber,
        access: {
          mytech: true,
          qrcode: true,
          myaccount: true,
          mycalendar: true,
          myrequests: true
        }
      };
      delete data.profile_picture;
      await RequestManager()
        .userApi.editUser(this.currentUser.id, { body: data })
        .then(response => {
          localStorage.setItem("currentUser", JSON.stringify(response));
          localStorage.setItem("isProfileFilled", "true");
          this.$router.push({ path: "/main" });
        });
      this.isDataSubmitting = false;
    }
  },
  created() {
    this.isIntroPassed = localStorage.getItem("isIntroPassed");
  }
};
</script>

<style lang="scss" scoped>
// .phone-block {
//   max-height: 100px;
// }
.phone-label {
  position: relative;
  margin-bottom: 0;
}
.form-control {
  position: relative !important;
  top: 2px !important;
}
.office-info__select-group {
  position: relative;
  top: -20px;
  // max-height: 75px;
  .page-select {
    position: relative;
    top: -2px;
  }
}
.scroll-container {
  width: 500px;
  margin: 0 auto;
  overflow: hidden;
  // max-height: 645px;
}
.admin-page__avatar {
  width: 97px;
  height: 97px;
  border-radius: 50%;
}
.create-user {
  margin: 0 auto;
  padding: 38px 72px 36px;
  width: 100%;
  max-width: 498px;
  //min-height: 672px;

  background: var(--main-card-color);
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
  position: relative;
  z-index: 1001;
}

.admin-page__main-title {
  margin-bottom: 28px;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.04em;

  color: var(--plus-button-color);
}

.office-info__select-group {
  display: flex;
  align-items: center;
}

.add-item__textarea {
  background: rgba(206, 206, 206, 0.2);
  padding: 20px 15px;
  margin-bottom: 24px;

  font-size: 14px;
  line-height: 16px;
  font-weight: 500;

  &::placeholder {
    color: #cfcfcf;
  }
}

.form-group__label {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;

  color: var(--main-text-color) !important;
}

.create-user__img-load-wrap {
  margin-bottom: 30px;
}

.create-user__btn-group {
  display: flex;
  align-items: center;
}

.create-user__btn {
  max-width: 100%;
}

.create-user__btn-prev {
  border: 1px solid #006b90;
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.01));
  margin-right: 7px;
}

.file-select {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  line-height: 14px;

  color: #1f1f1f;
}

.file-select > .select-button {
  background-image: url("../../resource/img/edit-ava.svg");
  background-repeat: no-repeat;
  font-size: 10px;
  width: 97px;
  height: 97px;
  cursor: pointer;
  margin-right: 17px;
}

.file-select > input[type="file"] {
  display: none;
}

.create-user__radio-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
  //justify-content: space-between;
}

.create-user__label-wrap {
  width: 50%;
  &:not(:last-child) {
    margin-bottom: 27px;
  }
}

.create-user__label {
  margin-left: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #4b4b67;
}

.phone-label {
  font-size: 14px;
  font-weight: 500;
}
.phone-label.over-top {
  color: #fff !important;
}
.phone {
  background: var(--auth-input-bg);
  border: 1px solid var(--auth-input-bg);
  box-sizing: border-box;
  border-radius: 6px;
  min-height: 56px;
  color: var(--main-text-color);
}

.no-tabindex,
.no-tabindex input {
  tabindex: -1 !important;
}
</style>
